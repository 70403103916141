import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Head from "../components/head"
import ArchiveCard from "../components/archive-card"
import * as styles from "./index.module.scss"
import axios from "axios"
const IndexPage = props => {
  // const posts = props.data.allWpPost.nodes
  // const customers = props.data.allWpWorks.nodes
  const backgroundImageRecruit = convertToBgImage(
    getImage(props.data.backgroundImageRecruit.childImageSharp)
  )
  const backgroundImageEnvironments = convertToBgImage(
    getImage(props.data.backgroundImageEnvironments.childImageSharp)
  )
  const backgroundImageFaq = convertToBgImage(
    getImage(props.data.backgroundImageFaq.childImageSharp)
  )
  const backgroundImageBlog = convertToBgImage(
    getImage(props.data.backgroundImageBlog.childImageSharp)
  )
  const [newsPosts, setNewsPosts] = useState([])
  const [blogPosts, setBlogPosts] = useState([])
  useEffect(() => {
    try {
      axios
        .get("/wp-json/wp/v2/posts", {
          params: {
            per_page: 3,
            _embed: "",
          },
        })
        .then(response => {
          setNewsPosts(response.data)
        })
        .catch(error => {
          console.log("axios posts : " + error)
        })
      axios
        .get("/wp-json/wp/v2/blog", {
          params: {
            per_page: 3,
            _embed: "",
          },
        })
        .then(response => {
          setBlogPosts(response.data)
        })
        .catch(error => {
          console.log("axios blog : " + error)
        })
    } catch (error) {
      console.log("try catch : " + error)
    }
  }, [])

  return (
    <Layout>
      <Head
        title="株式会社アイテクス | 鹿児島のシステム開発会社"
        description="株式会社アイテクスは「ITを通して社会生活を豊かにする」のスローガンのもとシステム開発やコンサルティング、および運用支援・保守等を行っています。ITのエキスパートとして「楽する仕事」が出来る環境を提供し、お客様の満足を通じて私たちの「夢や幸せ」を実現します。"
      />
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className={styles.eyecatch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="513"
                height="168.5019989013672"
                viewBox="0 0 513 168.502"
              >
                <defs>
                  <style>
                    {`
      .cls-1, .cls-11, .cls-12, .cls-13 {
        fill: none;
      }

      .cls-2 {
        fill: #071013;
      }

      .cls-3 {
        fill: #005dab;
      }

      .cls-4 {
        fill: url(#radial-gradient);
      }

      .cls-5 {
        fill: url(#radial-gradient-2);
      }

      .cls-6 {
        fill: url(#radial-gradient-3);
      }

      .cls-7 {
        fill: url(#radial-gradient-4);
      }

      .cls-8 {
        fill: url(#radial-gradient-5);
      }

      .cls-9 {
        fill: url(#radial-gradient-6);
      }

      .cls-10 {
        fill: url(#linear-gradient);
      }

      .cls-11 {
        stroke: #071013;
      }

      .cls-12 {
        stroke: #005dab;
      }

      .cls-13 {
        stroke: #003780;
      }`}
                  </style>
                  <radialGradient
                    id="radial-gradient"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientTransform="translate(0)"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0.699" stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#e7ebed"></stop>
                  </radialGradient>
                  <radialGradient
                    id="radial-gradient-2"
                    cx="1.794"
                    cy="0.142"
                    r="0.968"
                    gradientTransform="translate(-0.484) scale(1.968 1)"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#6bc6f3"></stop>
                    <stop offset="0.184" stop-color="#22b4ee"></stop>
                    <stop offset="0.436" stop-color="#008fdd"></stop>
                    <stop offset="0.736" stop-color="#0071c4"></stop>
                    <stop offset="1" stop-color="#0060b3"></stop>
                  </radialGradient>
                  <radialGradient
                    id="radial-gradient-3"
                    cx="0.753"
                    cy="-0.273"
                    r="0.958"
                    gradientTransform="translate(0.046) scale(0.908 1)"
                    xlinkHref="#radial-gradient-2"
                  ></radialGradient>
                  <radialGradient
                    id="radial-gradient-4"
                    cx="0.07"
                    cy="-0.277"
                    r="0.956"
                    gradientTransform="translate(-0.322) scale(1.643 1)"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#009de3"></stop>
                    <stop offset="0.196" stop-color="#0086d4"></stop>
                    <stop offset="0.564" stop-color="#005aac"></stop>
                    <stop offset="1" stop-color="#003780"></stop>
                  </radialGradient>
                  <radialGradient
                    id="radial-gradient-5"
                    cx="0.534"
                    cy="0.346"
                    r="1.954"
                    gradientTransform="translate(0.206) scale(0.588 1)"
                    xlinkHref="#radial-gradient-2"
                  ></radialGradient>
                  <radialGradient
                    id="radial-gradient-6"
                    cx="0.632"
                    cy="0.359"
                    r="0.609"
                    gradientTransform="translate(0)"
                    xlinkHref="#radial-gradient-2"
                  ></radialGradient>
                  <linearGradient
                    id="linear-gradient"
                    x1="0.315"
                    y1="1.046"
                    x2="0.68"
                    y2="-0.031"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop
                      offset="0.27"
                      stop-color="#fefeff"
                      stop-opacity="0"
                    ></stop>
                    <stop
                      offset="0.396"
                      stop-color="#fefeff"
                      stop-opacity="0.012"
                    ></stop>
                    <stop
                      offset="0.497"
                      stop-color="#fefeff"
                      stop-opacity="0.051"
                    ></stop>
                    <stop
                      offset="0.59"
                      stop-color="#fefeff"
                      stop-opacity="0.118"
                    ></stop>
                    <stop
                      offset="0.678"
                      stop-color="#fefeff"
                      stop-opacity="0.212"
                    ></stop>
                    <stop
                      offset="0.762"
                      stop-color="#fefeff"
                      stop-opacity="0.333"
                    ></stop>
                    <stop
                      offset="0.843"
                      stop-color="#fefeff"
                      stop-opacity="0.482"
                    ></stop>
                    <stop
                      offset="0.922"
                      stop-color="#fefeff"
                      stop-opacity="0.655"
                    ></stop>
                    <stop
                      offset="0.997"
                      stop-color="#fefeff"
                      stop-opacity="0.851"
                    ></stop>
                    <stop
                      offset="1"
                      stop-color="#fefeff"
                      stop-opacity="0.859"
                    ></stop>
                  </linearGradient>
                </defs>
                <g id="eyecatch-logo-2" transform="translate(-887.5 -438.481)">
                  <rect
                    id="background"
                    className="cls-1 svg-elem-1"
                    width="512"
                    height="168"
                    transform="translate(888 438.982)"
                  ></rect>
                  <g id="draw">
                    <g id="ITxS" transform="translate(1068.706 484.39)">
                      <path
                        id="path"
                        className="cls-2 svg-elem-2"
                        d="M258.185,662.009V573.49h19.283v88.519Z"
                        transform="translate(-258.185 -570.038)"
                      ></path>
                      <path
                        id="path-2"
                        data-name="path"
                        className="cls-2 svg-elem-3"
                        d="M331.926,590.317v72.109H312.638V590.317H273.593V573.791h96.638v16.526Z"
                        transform="translate(-241.836 -569.718)"
                      ></path>
                      <path
                        id="path-3"
                        data-name="path"
                        className="cls-2 svg-elem-4"
                        d="M400.332,647.22,373.86,618.484,400.3,589.856,388.536,578.8,362.925,606.61,337.31,578.8l-11.759,11.054,26.434,28.628L325.522,647.22l12.01,10.614,25.393-27.5,25.387,27.5Z"
                        transform="translate(-186.736 -564.401)"
                      ></path>
                      <path
                        id="path-4"
                        data-name="path"
                        className="cls-2 svg-elem-5"
                        d="M437.674,589.963H405.618c-13.012,0-15.116,2.288-15.116,6.87v6.148c0,4.337,2.459,6.507,16.192,6.507h31.827c6.915,0,15.318,1.018,21.7,5.528,5.5,3.889,9.454,11.2,9.454,16.936v5.975c0,8.209-3.071,15.058-9.221,19.422-4.887,3.473-13.922,4.72-22.167,4.72H371.1V645.416h70.058c6.771,0,9.229-3.219,9.229-7.531v-4.806c0-4.629-4.013-6.946-11.5-6.946H400.707c-7.234,0-15.135-1.76-20.922-5.983a20.344,20.344,0,0,1-8.57-15.864v-7.447a22.271,22.271,0,0,1,9.343-17.882c6.222-4.549,14.51-5.521,25.827-5.521h31.289Z"
                        transform="translate(-138.376 -570.096)"
                      ></path>
                      <path
                        id="path-5"
                        data-name="path"
                        className="cls-3 svg-elem-6"
                        d="M429.474,583.454a11.64,11.64,0,1,1-11.635-11.639A11.638,11.638,0,0,1,429.474,583.454Z"
                        transform="translate(-101.135 -571.815)"
                      ></path>
                    </g>
                    <g id="icon" transform="translate(888 438.982)">
                      <path
                        id="path-6"
                        data-name="path"
                        className="cls-4 svg-elem-7"
                        d="M306.979,636.644a74.785,74.785,0,1,1-74.785-74.754A74.77,74.77,0,0,1,306.979,636.644Z"
                        transform="translate(-157.409 -544.992)"
                      ></path>
                      <path
                        id="path-7"
                        data-name="path"
                        className="cls-5 svg-elem-8"
                        d="M208.794,563.86a75.142,75.142,0,0,0-45.2,101.152,134.181,134.181,0,0,1,21.813-35.639C185.88,605.271,193.72,581.061,208.794,563.86Z"
                        transform="translate(-157.409 -543.143)"
                      ></path>
                      <path
                        id="path-8"
                        data-name="path"
                        className="cls-6 svg-elem-9"
                        d="M273.791,585.8c-23.4-1.111-47.318,4.691-63.073,13.665a153.4,153.4,0,0,0-15.95,10.321c-4.558,21.208-3.489,43.338,5.629,61.243-10.677-13.773-16.72-31.752-18.222-50.5A134.177,134.177,0,0,0,161.227,647.2a75.229,75.229,0,0,0,50.829,40.64c2.592-22.539,9.173-44.129,26.13-67.4A140.182,140.182,0,0,1,273.791,585.8Z"
                        transform="translate(-153.826 -523.34)"
                      ></path>
                      <path
                        id="path-9"
                        data-name="path"
                        className="cls-7 svg-elem-10"
                        d="M203.012,688.383a75.147,75.147,0,0,0,58.023-98.265,99.051,99.051,0,0,0-21.169-4.211C211.377,612.593,200.691,657.069,203.012,688.383Z"
                        transform="translate(-115.49 -523.111)"
                      ></path>
                      <path
                        id="path-10"
                        data-name="path"
                        className="cls-8 svg-elem-11"
                        d="M181.5,612.437c22.784-17.769,54.469-26.535,85.23-24.914a74.967,74.967,0,0,0-45.571-25.2C204.235,571.659,189.989,590.564,181.5,612.437Z"
                        transform="translate(-135.388 -544.583)"
                      ></path>
                      <path
                        id="path-11"
                        data-name="path"
                        className="cls-9 svg-elem-12"
                        d="M208.689,564.676a11.012,11.012,0,1,1-10.509-11.492A11.017,11.017,0,0,1,208.689,564.676Z"
                        transform="translate(-130.204 -553.173)"
                      ></path>
                      <path
                        id="path-12"
                        data-name="path"
                        className="cls-10 svg-elem-13"
                        d="M280.822,608.563c0,24.424-25.592,44.228-57.16,44.228s-57.162-19.8-57.162-44.228,25.594-44.226,57.162-44.226S280.822,584.139,280.822,608.563Z"
                        transform="translate(-148.876 -542.695)"
                      ></path>
                    </g>
                  </g>
                  <g id="line">
                    <g
                      id="ITxS-2"
                      data-name="ITxS"
                      transform="translate(1068.706 484.39)"
                    >
                      <path
                        id="path-13"
                        data-name="path"
                        className="cls-11 svg-elem-14"
                        d="M258.185,662.009V573.49h19.283v88.519Z"
                        transform="translate(-258.185 -570.038)"
                      ></path>
                      <path
                        id="path-14"
                        data-name="path"
                        className="cls-11 svg-elem-15"
                        d="M331.926,590.317v72.109H312.638V590.317H273.593V573.791h96.638v16.526Z"
                        transform="translate(-241.836 -569.718)"
                      ></path>
                      <path
                        id="path-15"
                        data-name="path"
                        className="cls-11 svg-elem-16"
                        d="M400.332,647.22,373.86,618.484,400.3,589.856,388.536,578.8,362.925,606.61,337.31,578.8l-11.759,11.054,26.434,28.628L325.522,647.22l12.01,10.614,25.393-27.5,25.387,27.5Z"
                        transform="translate(-186.736 -564.401)"
                      ></path>
                      <path
                        id="path-16"
                        data-name="path"
                        className="cls-11 svg-elem-17"
                        d="M437.674,589.963H405.618c-13.012,0-15.116,2.288-15.116,6.87v6.148c0,4.337,2.459,6.507,16.192,6.507h31.827c6.915,0,15.318,1.018,21.7,5.528,5.5,3.889,9.454,11.2,9.454,16.936v5.975c0,8.209-3.071,15.058-9.221,19.422-4.887,3.473-13.922,4.72-22.167,4.72H371.1V645.416h70.058c6.771,0,9.229-3.219,9.229-7.531v-4.806c0-4.629-4.013-6.946-11.5-6.946H400.707c-7.234,0-15.135-1.76-20.922-5.983a20.344,20.344,0,0,1-8.57-15.864v-7.447a22.271,22.271,0,0,1,9.343-17.882c6.222-4.549,14.51-5.521,25.827-5.521h31.289Z"
                        transform="translate(-138.376 -570.096)"
                      ></path>
                      <path
                        id="path-17"
                        data-name="path"
                        className="cls-12 svg-elem-18"
                        d="M429.474,583.454a11.64,11.64,0,1,1-11.635-11.639A11.638,11.638,0,0,1,429.474,583.454Z"
                        transform="translate(-101.135 -571.815)"
                      ></path>
                    </g>
                    <g
                      id="icon-2"
                      data-name="icon"
                      transform="translate(888 438.982)"
                    >
                      <path
                        id="path-18"
                        data-name="path"
                        className="cls-13 svg-elem-19"
                        d="M208.794,563.86a75.142,75.142,0,0,0-45.2,101.152,134.181,134.181,0,0,1,21.813-35.639C185.88,605.271,193.72,581.061,208.794,563.86Z"
                        transform="translate(-157.409 -543.143)"
                      ></path>
                      <path
                        id="path-19"
                        data-name="path"
                        className="cls-13 svg-elem-20"
                        d="M273.791,585.8c-23.4-1.111-47.318,4.691-63.073,13.665a153.4,153.4,0,0,0-15.95,10.321c-4.558,21.208-3.489,43.338,5.629,61.243-10.677-13.773-16.72-31.752-18.222-50.5A134.177,134.177,0,0,0,161.227,647.2a75.229,75.229,0,0,0,50.829,40.64c2.592-22.539,9.173-44.129,26.13-67.4A140.182,140.182,0,0,1,273.791,585.8Z"
                        transform="translate(-153.826 -523.34)"
                      ></path>
                      <path
                        id="path-20"
                        data-name="path"
                        className="cls-13 svg-elem-21"
                        d="M203.012,688.383a75.147,75.147,0,0,0,58.023-98.265,99.051,99.051,0,0,0-21.169-4.211C211.377,612.593,200.691,657.069,203.012,688.383Z"
                        transform="translate(-115.49 -523.111)"
                      ></path>
                      <path
                        id="path-21"
                        data-name="path"
                        className="cls-13 svg-elem-22"
                        d="M181.5,612.437c22.784-17.769,54.469-26.535,85.23-24.914a74.967,74.967,0,0,0-45.571-25.2C204.235,571.659,189.989,590.564,181.5,612.437Z"
                        transform="translate(-135.388 -544.583)"
                      ></path>
                      <path
                        id="path-22"
                        data-name="path"
                        className="cls-13 svg-elem-23"
                        d="M208.689,564.676a11.012,11.012,0,1,1-10.509-11.492A11.017,11.017,0,0,1,208.689,564.676Z"
                        transform="translate(-130.204 -553.173)"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <p className="title mt-4">ITを通して、社会生活を豊かにする。</p>
            <p className="subtitle">Enriching social life through IT.</p>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <div className="container">
          <div className="columns is-variable is-5 is-vcentered">
            <div className="column">
              <h2 className="title">ITを通して社会生活を豊かに</h2>
              <div className="content">
                <p>
                  ITの力で「<strong>可能性</strong>」を最大に。
                  <br />
                  私たちの使命は、企業や社会が抱える課題を解決するだけでなく、ITが持つ可能性を最大限に引き出し豊かな社会生活を創造すること。
                </p>
                <p>
                  きっとその先には笑顔溢れる社会が待っているはず。 <br />「
                  <strong>不可能を可能に、可能をもっと楽に楽しく</strong>」
                  <br />
                  私たちは革新的な技術で世の中を動かす企業を目指します。
                </p>
              </div>
              <Link
                to="/philosophy/"
                className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
              >
                企業理念
              </Link>
            </div>
            <div className="column">
              <StaticImage
                src="../../content/assets/16x9-sakurajima-machi.jpg"
                alt="ITを通して社会生活を豊かに"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
      </section>
      <section className={"section is-medium " + styles.ourfutures}>
        <div className="container">
          <h2 className="title">「想像」×「テクノロジー」で現実を変える。</h2>
          <h3 className="subtitle">Our FutureS</h3>
          <div className="content">
            <p>楽しいと思える社会に！</p>
            <p>
              まずは、自分たちが楽しもう。
              <br />
              次に、周囲からも楽しい人と言われよう。
              <br />
              そして、誰かの人生を楽しくしよう。
            </p>
            <p>
              「おもしろそう」の遊びと仕事の間から価値観を変えるイノベーションへと繋る。
              <br />
              私たちが想像するテクノロジーで社会や人々に夢や幸せを届けます。
            </p>
          </div>
          <div className="content mt-6">
            <h4>システム開発 ウェブシステム開発</h4>
            <p>
              業務を効率化するシステム開発や、オリジナルサービスの開発、
              <br />
              ウェブサイト制作、ITインフラの構築支援などお客様のニーズに応えます。
            </p>
            <h4>コンサルティング</h4>
            <p>
              IT戦略策定支援やナレッジマネジメントツールの導入・支援、
              <br />
              ITリテラシーの底上げ支援、IT部門の立ち上げをサポートします。
            </p>
            <h4>サポート</h4>
            <p>
              IT機器で不具合が生じた際に対応する保守からトラブル予防のための運用まで
              <br />「<strong>お客様のIT専任者</strong>
              」としてトータルに支援致します。
            </p>
          </div>
        </div>
        <div className={"mt-6 " + styles.ourfutures__image1}>
          <StaticImage
            src="../../content/assets/16x9-AMU-kagoshima.jpg"
            alt="ITを通して社会生活を豊かに"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <h2 className="title has-text-centered">ニュース</h2>
        <h3 className="subtitle has-text-centered">News</h3>
        <div className="container">
          <div className="columns is-variable is-5 is-multiline">
            {newsPosts.map(post => {
              return (
                <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
                  <ArchiveCard post={post} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="has-text-centered mt-5">
          <a
            className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            href="https://itxs2021.xsrv.jp/news/"
          >
            ニュースー一覧へ
          </a>
        </div>
      </section>
      <section className="section is-medium">
        <h2 className="title has-text-centered">ブログ</h2>
        <h3 className="subtitle has-text-centered">Blog</h3>
        <div className="container">
          <div className="columns is-variable is-5 is-multiline">
            {blogPosts.map(post => {
              return (
                <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
                  <ArchiveCard post={post} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="has-text-centered mt-5">
          <a
            className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            href="https://itxs2021.xsrv.jp/news/"
          >
            ニュースー一覧へ
          </a>
        </div>
      </section>
      {/* <section className="section is-medium">
        <h2 className="title has-text-centered">お客様紹介</h2>
        <h3 className="subtitle has-text-centered">Works</h3>
        <div className="container">
          <div className="columns is-variable is-5 is-multiline">
            {customers.map(post => {
              return (
                <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
                  <ArchiveCard post={post} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="has-text-centered mt-5">
          <a
            to="/customers/"
            className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
          >
            お客様紹介一覧へ
          </a>
        </div>
      </section> */}
      <div className="columns is-gapless mb-0">
        <div className="column">
          <Link className={styles.banner} to="/recruit/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>採用情報</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageRecruit}
              preserveStackingContext
            ></BackgroundImage>
          </Link>
        </div>
        <div className="column">
          <Link className={styles.banner} to="/environments/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>勤務環境／ワークサポート</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageEnvironments}
              preserveStackingContext
            ></BackgroundImage>
          </Link>
        </div>
      </div>
      <div className="columns is-gapless mb-0">
        <div className="column">
          <Link className={styles.banner} to="/faq/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>よくあるご質問</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageFaq}
              preserveStackingContext
            ></BackgroundImage>
          </Link>
        </div>
        <div className="column">
          <a className={styles.banner} to="/blog/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>ブログ</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageBlog}
              preserveStackingContext
            ></BackgroundImage>
          </a>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
export const pageQuery = graphql`
  query Index {
    backgroundImageRecruit: file(relativePath: { eq: "16x9-segodon.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    backgroundImageEnvironments: file(
      relativePath: { eq: "16x9-sakurajima.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    backgroundImageFaq: file(relativePath: { eq: "16x9-goroumon.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    backgroundImageBlog: file(relativePath: { eq: "16x9-coffee-break.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
